export const useModal = (config?: {
  closeOnClickOutside: boolean
}) => {
  const visible = ref(false)

  const show = () => visible.value = true
  const hide = () => visible.value = false

  const Modal = defineComponent({
    name: 'Modal',
    setup: (_, { slots }) => {
      const dialog = templateRef<HTMLDialogElement>('dialog')

      watch(visible, value => {
        value ? dialog.value.showModal() : dialog.value.close()
      })

      const dialogClicked = (
        event: PointerEvent
      ) => {
        if (event.target != dialog.value)
          return

        if (config?.closeOnClickOutside == undefined || config?.closeOnClickOutside === true)
          hide()
      }

      return () => {
        const layout = h('div', { class: 'w-full h-full' }, slots.default && slots.default())

        return h(
          'dialog',
          {
            class: 'min-w-72 rounded-lg shadow-md shadow-gray-400',
            ref: 'dialog',
            onClick: dialogClicked
          },
          layout
        )
      }
    }
  })

  return { Modal, show, hide, visible }
}
